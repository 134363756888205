<template>
  <v-form v-bind="$attrs" v-on="$listeners">
    <v-container fluid>
      <!-- Project Id & Name-->
      <v-row dense>
        <!-- project Id-->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="projectId"
            label="Project Id"
            readonly
          ></v-text-field>
        </v-col>

        <!-- project Name-->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="projectName"
            label="Project"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Subject & Project Manager -->
      <v-row dense>
        <!-- Subject -->
        <v-col cols="12" md="6">
          <v-text-field v-model="subject" label="Subject" readonly>
            <template v-slot:prepend>
              <record-badge-button
                :record="record"
                :to="folderModuleRecordRouteLocation(record)"
                v-if="canNavigateToFolderModuleRecord(record)"
              >
                <template v-slot:tooltip>
                  {{ tooltipNavigateToRecord(record) }}
                </template>
              </record-badge-button>
            </template>
          </v-text-field>
        </v-col>

        <!-- project Manager-->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconManager"
            v-model="projectManager"
            label="Project Manager"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Project Template -->
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="templateId"
            label="Template Id"
            readonly
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="templateName"
            label="Template"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Task id & name -->
      <v-row dense>
        <!-- id -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="taskId"
            label="Task Id"
            readonly
          ></v-text-field>
        </v-col>

        <!-- name -->
        <v-col cols="12" md="6">
          <v-text-field v-model="taskName" label="Task" readonly></v-text-field>
        </v-col>
      </v-row>

      <!-- date time when task is Created, Effective -->
      <v-row dense>
        <!-- created date time -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconCalendar"
            v-model="taskCreated"
            label="Created"
            readonly
          ></v-text-field>
        </v-col>

        <!-- effective date time -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconCalendar"
            v-model="taskEffective"
            label="Effective"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- status -->
      <v-row dense>
        <!-- status -->
        <v-col cols="12" md="6">
          <v-text-field readonly v-model="taskStatus" label="Status">
          </v-text-field>
        </v-col>

        <!-- status changed -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconCalendar"
            v-model="statusChanged"
            label="Status Changed"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- assignment -->
      <v-row dense>
        <!-- assignment Method -->
        <v-col cols="12" md="6">
          <v-text-field readonly v-model="assignmentMethod" label="Assignment">
          </v-text-field>
        </v-col>

        <!-- Assignee -->
        <v-col cols="12" md="6">
          <v-text-field
            :prepend-icon="iconUser"
            readonly
            v-model="assignee"
            label="Assigned To"
          >
            <template v-slot:append v-if="visibleReAssignButton">
              <v-btn icon @click="showReAssignDialog"
                ><v-icon>{{ iconMore }}</v-icon></v-btn
              >
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!--  Priority & Duration -->
      <v-row dense>
        <!-- Priority -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="priorityText"
            label="Priority"
            readonly
          ></v-text-field>
        </v-col>

        <!-- task Duration -->
        <v-col cols="12" md="6" v-show="!isMilestone">
          <v-text-field readonly v-model="taskDuration" label="Duration">
          </v-text-field>
        </v-col>
      </v-row>

      <!-- Due Date & (is Due &  is Overdue) -->
      <v-row dense>
        <!-- Due Date -->
        <v-col cols="12" md="6" v-show="!isMilestone">
          <v-text-field
            :prepend-icon="iconCalendar"
            :error-messages="dueDateMessage"
            readonly
            label="Due Date"
            v-model="dueDate"
          >
          </v-text-field>
        </v-col>

        <!-- is Due -->
        <v-col cols="12" md="3" v-show="isDue">
          <v-checkbox
            v-model="isDue"
            label="Due"
            readonly
            :color="dueDateColorClass"
          ></v-checkbox>
        </v-col>

        <!-- is Overdue -->
        <v-col cols="12" md="3" v-show="isOverdue">
          <v-checkbox
            v-model="isOverdue"
            label="Overdue"
            readonly
            :color="dueDateColorClass"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- Milestone -->
      <v-row dense v-show="isMilestone">
        <v-col cols="12" md="6">
          <v-checkbox
            readonly
            v-model="isMilestone"
            label="Milestone"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- notes -->
      <v-row dense v-if="visibleNotes">
        <v-col>
          <task-notes :notes="notes"></task-notes>
        </v-col>
      </v-row>
    </v-container>

    <!-- Assign User Dialog -->
    <assign-user-dialog
      :task="task"
      :visible="visibleDialogAssignUser"
      :perform-action="onUserAssignment"
      @close="onCloseAssignUserTaskDialog"
    ></assign-user-dialog>
  </v-form>
</template>

<script>
import { fullRecordName } from "@/services/record/recordService";

import { taskMixin } from "@/mixins/shared/workflow/task/taskMixin";
import { folderRecordNavigatable } from "@/mixins/shared/navigatable/folderRecordNavigatable";

export default {
  name: "FormTask",
  inheritAttrs: false,
  mixins: [taskMixin, folderRecordNavigatable],
  components: {
    RecordBadgeButton: () => import("@/components/record/RecordBadgeButton")
  },
  props: {
    record: undefined
  },
  methods: {
    /**
     * overwrite folderRecordNavigatable.tooltipNavigateToRecord()
     * @param record
     * @return {string}
     */
    tooltipNavigateToRecord(record) {
      return `Jump to ${record?.recordType} ${fullRecordName(record)}`;
    }
  }
};
</script>
